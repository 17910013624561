import { useParams } from '@solidjs/router';

import PostList from '~/components/PostList';

export default function Month() {
  const parameters = useParams();

  return (
    <PostList
      filter={{
        category: { slug: parameters.category },
        publishedAt_GT: parameters.year,
        publishedAt_LT: (Number.parseInt(parameters.year, 10) + 1).toString(),
      }}
    />
  );
}
